import React from 'react'
import HeroContact from '../../components/contact/HeroContact/HeroContact'
import Footer from '../../components/english/Footer/Footer'


const Contact = () => {
  return (
    <div className='englishContainer'>
       <HeroContact/>
      
       
    </div>
  )
}

export default Contact