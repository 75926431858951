import { Grid, Modal, Typography } from '@mui/material';
import React, { useState } from 'react'
import "./Seed.modules.css";
import genesis from "../../../assets/Seed/genesis.png";
import genesiss from "../../../assets/Seed/genesiss.gif";
import nota from "../../../assets/Seed/genesisNota.png";
import diablo from "../../../assets/Seed/diablo.jpg";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Box from "@mui/material/Box";
import CancelIcon from "../../../assets/Team/Cancel.png";
import Pulse from "react-reveal/Pulse";
import CardContent from "@mui/material/CardContent";
import more from "../../../assets/Seed/more.png";
import { Fade } from 'react-reveal';
import Shake from 'react-reveal/Shake';

const Seed = () => {
 
  const [open, isModalOpen] = useState(false);
  const handleClose = () => isModalOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#E8154E",
    borderRadius: "3%",
    border: "2px solid #FFFFFF",
    boxShadow: 24,
    p: 4,
    "&:focus": {
      outline: "none",
    },
  };

  
  
  return (
    <div className='seedContainer'>
        <div className='seedCopyWrapper'>
        <Typography
            className=""
            sx={{
                fontFamily: "Rum Raisin",
                margin: "0%",
                color: "#FFF",
                position: "",
                lineHeight: "1,5",
                padding: "2% 0%",
                textAlign: "center",
            }}
            fontSize={{
                xs: "40px;",
                sm: "calc(50px + 30 *((100vw - 768px)/672));",
                lg: "80px;",
              }}
        >
             <Fade right>The Seed of the collection</Fade> 
            </Typography>
            <Typography
                className=''
                sx={{
                    fontFamily: "Rum Raisin",
                    margin: "-4% 0%",
                    color: "#FFF",
                    position: "",
                    lineHeight: "1",
                    padding: "0% 0%",
                    textAlign: "center",
                }}
                fontSize={{
                    xs: "25px;",
                    sm: "calc(25px + 15 *((100vw - 768px)/672));",
                    lg: "40px;",
                  }}
            >
                <Fade right>A note by Baby Yors</Fade>
            </Typography>
            <div className='moreSeedContainer'>
                <Shake>
                    <img src={more} className='moreImgSeed' alt='' onClick={()=> isModalOpen(true)}/>
                </Shake>
            </div>
        </div>
        <div className='elementsWrapper'>
            <Grid container justifyContent="center" alignItems="center" spacing={8}>
                <Grid item xs={3}>
                    <div className="genesisWrapper" onClick={()=> isModalOpen(true)}>
                        <Fade left>
                        <img 
                            src={genesis}
                            className="genesisImg"
                            alt=""
                        />
                        </Fade>
                    </div>
                </Grid>
                <Grid item xs={3} >
                    <div className="genesisWrapper" onClick={()=> isModalOpen(true)}>
                        <img 
                            src={nota}
                            className="genesisImg2"
                            alt=""
                        />
                    </div>
                </Grid>
                <Grid item xs={3} >
                    <div className="genesisWrapper" onClick={()=> isModalOpen(true)}>
                        <Fade right>
                        <img 
                            src={diablo}
                            className="genesisImg"
                            alt=""
                        />
                        </Fade>
                    </div>
                </Grid>
            </Grid>
        </div>
        <div className='carouselS'>
                <div className="genesisWrapper" >
                        <img 
                            src={genesiss}
                            className="genesisImg"
                            alt=""
                            onClick={()=> isModalOpen(true)}
                        />
                        <img src={more} className='moreImgSeed' alt='' onClick={()=> isModalOpen(true)}/>
                </div>
        </div>
       
        <Modal open={open} className="teamModal " sx={{}}>
            <Box sx={style} className="modalSeed">
            <Pulse delay={500}>
                <img
                alt=""
                src={CancelIcon}
                className="cancelIcon"
                onClick={handleClose}
                />
            </Pulse>
            
            <CardContent>
                <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                marginTop={0}
                >
                <Typography
                    id="modal-modal-description"
                    color="white"
                    sx={{ mt: 0 }}
                    fontSize={{
                    xs: "12px;",
                    sm: "16px;",
                    lg: "18px;",
                    }}
                >
                    “Back in 2013, in my early years in New York City, I  was an extra in ‘The Wolf of Wall Street,” the Martin Scorsese-directed movie with Leonardo DiCaprio in the lead role. I worked on it in for about a month, and one day, while we were between takes, I was reading David Lynch’s book “Big Fish.” I got a text message from my father: “Aunt Ana Maria had an accident. Pray.” 
                    <br/>
                    <br/>
                    I was confused, but I knew. I didn’t know she was dead, but I sensed it. I couldn’t speak to people and I didn’t want to. Everything blurred into the background and all I could think about was mortality. Not just hers, but my own, my family’s. This had happened in Argentina and I couldn’t be there to help. 
                    <br/>
                    <br/>
                    I still had to keep shooting for another six hours, and couldn’t hear the full story of what happened. I switched off my phone and I started writing and doodling on a piece of paper. After we finished the day, I called my dad and he told me my aunt had in fact died. The day after, I made the doodle into a painting, and it became my favorite painting. Something about it felt trascendental — like the themes in Lynch’s book — and filled with personality. It also evoked the style of painting I’ve been developing since I was a child. 
                    <br/>
                    <br/>
                    Years later, I vectorized the painting and decided to use it as a template for other characters. That’s when “Los Diablos“ and the characters underneath the costumes came to life. This collection is then an extension of me. Not only my culture, but my feelings and coping mechanisms are sealed into these characters.                 </Typography>
                </Grid>
            </CardContent>
            </Box>        
        </Modal>
    </div>
  )
}

export default Seed

/*
    <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
              <SwiperSlide>
                  <div className='genesisWrapper'>
                    <img 
                        src={genesis}
                        className="genesisImg"
                        alt=""
                    />
                  </div>
              </SwiperSlide>
              <SwiperSlide>
                  <div className='genesisWrapper'>
                    <img 
                        src={nota}
                        className="genesisImg"
                        alt=""
                    />
                  </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='genesisWrapper'>
                    <img 
                        src={diablo}
                        className="genesisImg"
                        alt=""
                    />
                </div>
              </SwiperSlide>
           </Swiper>
*/