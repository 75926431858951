import React from "react";
import "./Team.modules.css";
import Evan from "../../../assets/Team/Evan.png";
import EvanBackground from "../../../assets/Team/evanBackground.png";
import LOHFOH from "../../../assets/Team/LOHFOH.png";
import LohfohBackground from "../../../assets/Team/lohfohBackground.png";
import Panda from "../../../assets/Team/Panda.png";
import PandaBackground from "../../../assets/Team/pandaBackground.png";
import Audrey from "../../../assets/Team/Audrey.png";
import Baby from "../../../assets/Team/Baby Yors.png";
import DT from "../../../assets/Team/DT.png";
import Garet from "../../../assets/Team/Garet.png";
import Matt from "../../../assets/Team/MattFront.jpeg";
import MattBackground from "../../../assets/Team/MattBackground.jpeg";
import AudreyBackground from "../../../assets/Team/audreyBackground.png";
import BabyBackground from "../../../assets/Team/babyBackground.png";
import DTBackground from "../../../assets/Team/dtBackground.png";
import GaretBackground from "../../../assets/Team/GaretBackground.png";
import CancelIcon from "../../../assets/Team/Cancel.png";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Clouds from "../../../assets/Homepage/clouds.png";
import Clouds2 from "../../../assets/clouds2.png";
import Clouds3 from "../../../assets/clouds3.png";
import props from "prop-types";
import { useState } from "react";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import TwitterIcon from "../../../assets/Socials/Twitter.png";
import InstagramIcon from "../../../assets/Socials/IG.png";
import Pulse from "react-reveal/Pulse";
import Fade from "react-reveal/Fade";
import Parallax, { Layer, AppearLayer } from "react-parallax-scroll";

const babyImg = Baby;
const dtImg = DT;
const audreyImg = Audrey;
const babyBackground = BabyBackground;
const dtBackground = DTBackground;
const audreyBackground = AudreyBackground;
const evanImg = Evan;
const pandaImg = Panda;
const lohfohImg = LOHFOH;
const evanBackground = EvanBackground;
const pandaBackground = PandaBackground;
const lohfohBackground = LohfohBackground;
const garetImg = Garet;
const garetBackgroundImg = GaretBackground;
const mattImg = Matt;
const mattBackground = MattBackground;

const members = [
  {
    id: 1,
    name: "Baby Yors",
    imgPath: babyImg,
    imgBackgroundPath: babyBackground,
    bio: "Baby Yors is a genre-defying artist who has performed across the globe in pursuit of bigger stages to express himself. He has gained fans across the globe who identify with his free spirit, singular voice, and visionary songs and videos.",
    role: "Founder",
    md: 4,
    classes: "",
    twitterUrl: "https://twitter.com/babyors",
    instaUrl: "https://instagram.com/babyyors",
    modalCont: true,
  },
  {
    id: 2,
    name: "DT",
    imgPath: dtImg,
    imgBackgroundPath: dtBackground,
    bio: "Danielle comes from an extensive Adtech background. A member of the ETH SD team who is focused on building a core group of diverse developers interested in building on top of Ethereum. ",
    role: "Project Manager",
    md: 4,
    classes: "",
    twitterUrl: "https://twitter.com/mrs_web3",
    instaUrl: "https://instagram.com/dtulimero.eth",
    modalCont: true,
  },
  {
    id: 3,
    name: "Audrey",
    imgPath: audreyImg,
    imgBackgroundPath: audreyBackground,
    role: "Developer",
    bio: "Audrey is a software developer drawn to the technical aspects and sociological possibilities of permissionless distributed systems. By day she is a Creative Technologist for J.Crew and by night a Web3 enthusiast.",
    md: 4,
    classes: "",
    twitterUrl: "https://twitter.com/girlcode_tech",
    instaUrl: "https://instagram.com/msaudreynicole",
    modalCont: true,
  },
  {
    name: "Evan",
    imgPath: evanImg,
    imgBackgroundPath: evanBackground,
    role: "Business",
    bio: "Evan is a startup founder and software engineer with expertise in business operations and strategy. He has been an advisor for several top NFT projects, consulting on market strategy, operations,and tokenomics.",
    md: 4,
    classes: "",
    twitterUrl: "https://twitter.com/uncle_ether",
    instaUrl: "https://www.instagram.com/losdiablosnft",
    modalCont: true,
  },
  {
    name: "LOFOH",
    imgPath: lohfohImg,
    imgBackgroundPath: lohfohBackground,
    role: "Marketing",
    bio: "LOFOH has 10 years of digital marketing, community building, and product development experience. He aims to showcase our teams capability to captivate, innovate, and execute prior to mint so our community can feel safe as investors.",
    md: 4,
    classes: "",
    twitterUrl: "https://twitter.com/bread_giver",
    instaUrl: "https://www.instagram.com/losdiablosnft",
    modalCont: true,
  },
  {
    name: "Panda",
    imgPath: pandaImg,
    imgBackgroundPath: pandaBackground,
    role: "Community Manager",
    bio: "Panda is a Community Moderator for Axie Infinity. He enjoys onboarding people into Web3 and addressing any questions/problems they might have. He is also a JPEG Degen.",
    md: 4,
    classes: "",
    twitterUrl: "https://twitter.com/pandafxgaming",
    instaUrl: "https://www.instagram.com/losdiablosnft",
    modalCont: true,
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 310,
  height: 385,
  bgcolor: "#F14902",
  borderRadius: "3%",
  border: "2px solid #FFFFFF",
  boxShadow: 24,
  p: 4,
  "&:focus": {
    outline: "none",
  },
};

function TeamComp() {
  const [open, isModalOpen] = useState(false);
  const [member, setMember] = useState(0);
  const handleClose = () => isModalOpen(false);

  const onClickModal = (id) => {
    isModalOpen(true);
    setMember(id);
  };

  return (
    <div>
    
    
    <div className="teamCont" id="Team">
      <Grid container spacing={0}>
        <Typography
          sx={{
            fontFamily: "Rum Raisin",
            color: "#0FFA08",
            lineHeight: "1.5",
            width: "100%",
            padding: "10%",
            textAlign: "center",
            margin: "6% 0% -5% 0%",
          }}
          fontSize={{
            xs: "40px;",
            sm: "calc(40px + 40 *((100vw - 768px)/672));",
            lg: "80px;",
          }}
        >
          <Fade left>Meet the Team</Fade>
        </Typography>
        {members.map((member, i) =>
          member.modalCont ? (
            <div className="modalCont">
              <Pulse delay={1000}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={member.md}
                  className={member.classes}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <div class="card">
                      <div class="face face--front">
                        <img
                          src={member.imgPath}
                          className="teamPng"
                          alt=""
                        ></img>
                      </div>
                      <div
                        class="face face--back pulse"
                        onClick={() => onClickModal(i)}
                      >
                        <img
                          src={member.imgBackgroundPath}
                          className="teamPng"
                          alt=""
                        ></img>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Pulse>
            </div>
          ) : (
            <Grid item className={member.classes} xs={12} sm={6} md={member.md}>
              <Pulse delay={1000}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div class="card">
                    <div class="face face--front">
                      <img
                        src={member.imgPath}
                        className="teamPng"
                        alt=""
                      ></img>
                    </div>
                    <div
                      class="face face--back pulse"
                      onClick={() => onClickModal(i)}
                    >
                      <img
                        src={member.imgBackgroundPath}
                        className="teamPng"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </Grid>
              </Pulse>
            </Grid>
          )
        )}

        <Modal open={open} className="teamModal" sx={{}}>
          <Box sx={style}>
            <Pulse delay={500}>
              <img
                alt=""
                src={CancelIcon}
                className="cancelIcon"
                onClick={handleClose}
              />
            </Pulse>
            <CardContent>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                marginTop={-10}
              >
                <Avatar
                  className="teamAvatars"
                  alt="team picture"
                  src={members[member].imgPath}
                  sx={{ width: 130, height: 130, marginBottom: 1 }}
                />
                <Typography
                  sx={{
                    fontFamily: "Rum Raisin",
                    margin: "0%",
                    color: "#FFD714",
                    position: "",
                    lineHeight: "1",
                    width: "100%",
                    padding: "0% 0%",
                    textAlign: "center",
                  }}
                  fontSize={{
                    xs: "20px;",
                    sm: "calc(14px + 2 *((100vw - 768px)/672));",
                    lg: "20px;",
                  }}
                >
                  {members[member].name} - {members[member].role}
                </Typography>
                <Grid
                  container
                  width="auto"
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="buttonContainer">
                    <IconButton
                      sx={{
                        "& .MuiIconButton-root:hover": {
                          backgroundColor: "#fff",
                        },
                        "& .MuiIconButton-root:after": {
                          backgroundColor: "transparent",
                        },
                        padding: 0.6,
                      }}
                      href={members[member].twitterUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      backgroundColor="transparent"
                    >
                      <img src={TwitterIcon} className="teamIcons" alt="" />
                    </IconButton>

                    <IconButton
                      sx={{
                        "& .MuiIconButton-root:hover": {
                          backgroundColor: "transparent",
                        },
                        "& .MuiIconButton-root:after": {
                          backgroundColor: "transparent",
                        },
                        padding: 0.6,
                      }}
                      href={members[member].instaUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="iconBtn"
                      backgroundColor="transparent"
                    >
                      <img src={InstagramIcon} className="teamIcons" alt="" />
                    </IconButton>
                  </div>
                </Grid>
                <Typography
                  id="modal-modal-description"
                  color="white"
                  sx={{
                    mt: 0,
                    textAlign: "center",
                  }}
                >
                  {members[member].bio}
                </Typography>
              </Grid>
            </CardContent>
          </Box>
        </Modal>
      </Grid>
    </div>
    </div>
  );
}

export default TeamComp;
