import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Origins from "./containers/OriginsPage/Origins";
import Contact from "./containers/Contact/Contact";
import Merch from "./containers/Merch/Merch";
import Irl from "./containers/Irl/Irl";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}/>
        <Route path="projects" element={<Origins />}/>
        <Route path="origins" element={<Origins />}/>
        <Route path="merch" element={<Merch />}/>
		<Route path="irl" element={<Irl />}/>
        <Route path="contact" element={<Contact/>}/>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
