import React from 'react'
import { Parallax, useParallax } from "react-scroll-parallax";
import background from "../../../assets/Contact/background.png";
import backgroundMobile from "../../../assets/ContactMobile/background-mobile.png";
import firstMountainMobile from "../../../assets/ContactMobile/mountain1-mobile.png";
import secondMountainMobile from "../../../assets/ContactMobile/mountain2-mobile.png";
import thirdMountainMobile from "../../../assets/ContactMobile/mountain3-mobile.png";
import sunMobile from "../../../assets/ContactMobile/sun-mobile.png";
import llamasMobile from "../../../assets/ContactMobile/llamas-mobile.png";
import diablaMobile from "../../../assets/ContactMobile/diabla-mobile.png";
import floorMobile  from "../../../assets/ContactMobile/floor-mobile.png";
import firstMountain from "../../../assets/Contact/mountain1.png";
import secondMountain from "../../../assets/Contact/mountain2.png";
import thirdMountain from "../../../assets/Contact/mountain3.png";
import sun from "../../../assets/Contact/sun.png";
import llamas from "../../../assets/Contact/llamas.png";
import diabla from "../../../assets/Contact/diabla.png";
import floor  from "../../../assets/Contact/floor.png";
import MainNavComp from '../../english/MainNav/MainNav';
import Footer from '../../english/Footer/Footer';
import { Typography } from '@mui/material';
import { Fade } from 'react-reveal';
import "./HeroContact.modules.css";

const parallax =
  useParallax <
  HTMLDivElement >
  {
    easing: "easeOutQuad",
    translateY: [0, 100],
};

const HeroContact = () => {
  return (
    <React.Fragment>
        <div className='contactContainer'>
            <section className='contactPngSection'>
                <img
                    ref={parallax.ref}
                    src={background}
                    className="parallaxElements hide-mobile backgroundC"
                    alt=""
                />
                <img
                    ref={parallax.ref}
                    src={backgroundMobile}
                    className="parallaxElements hide-desktop backgroundC"
                    alt=""
                />
                <Fade top>
                    <img
                        ref={parallax.ref}
                        src={sun}
                        className="parallaxElements hide-mobile sun"
                        alt=""
                    />
                </Fade>
                <img
                    ref={parallax.ref}
                    src={secondMountain}
                    className="parallaxElements hide-mobile secondMountainC"
                    alt=""
                />
                <img
                    ref={parallax.ref}
                    src={secondMountainMobile}
                    className="parallaxElements hide-desktop secondMountainC"
                    alt=""
                />
                
                <img
                    ref={parallax.ref}
                    src={thirdMountain}
                    className="parallaxElements hide-mobile thirdMountainC"
                    alt=""
                />
                <img
                    ref={parallax.ref}
                    src={thirdMountainMobile}
                    className="parallaxElements hide-desktop thirdMountainC"
                    alt=""
                />
                
                <img
                    ref={parallax.ref}
                    src={firstMountain}
                    className="parallaxElements hide-mobile firstMountainC"
                    alt=""
                />
                <img
                    ref={parallax.ref}
                    src={firstMountainMobile}
                    className="parallaxElements hide-desktop firstMountainC"
                    alt=""
                />
                
                <img
                    ref={parallax.ref}
                    src={sunMobile}
                    className="parallaxElements hide-desktop sun"
                    alt=""
                />
                <Typography
                    className="contactTitle hide-mobile"
                    sx={{
                      fontFamily: "Rum Raisin",
                      color: "#F8117B",
                      position: "absolute",
                      lineHeight: "1.5",
                      right: "22%",

                    }}
                    fontSize={{
                        xs: "24px;",
                        sm: "34px",
                        lg: "40px;",
                      }}                
                >
                    <div onClick={() => {navigator.clipboard.writeText(this.state.textToCopy)}}><a className='at press' href={`mailto:${'m@babyyors.com'}?subject=${"Los Diablos"}&body=${""}`}>m@babyyors.com</a></div>
                </Typography>
                <Typography
                    className="contactTitle hide-desktop"
                    sx={{
                      fontFamily: "Rum Raisin",
                      color: "#F8117B",
                      position: "",
                      lineHeight: "1.5",
                      width: "auto",
                      marginLeft: "10%",
                      marginTop: "5%",
                      textAlign: "center",
                    }}
                    fontSize={{
                        xs: "24px;",
                        sm: "34px",
                        lg: "40px;",
                      }}                
                >
                    <div onClick={() => {navigator.clipboard.writeText(this.state.textToCopy)}}><a className='at press' href={`mailto:${'m@babyyors.com'}?subject=${"Los Diablos"}&body=${""}`}>m@babyyors.com</a></div>
                </Typography>
                <Typography
                    className="contactTitle hide-mobile"
                    sx={{
                      fontFamily: "Rum Raisin",
                      color: "#FFCE00",
                      position: "absolute",
                      lineHeight: "1.5",
                      right: "30%",
                      top: "28%",

                    }}
                    fontSize={{
                        xs: "24px;",
                        sm: "34px",
                        lg: "40px;",
                      }} 
                                     
                >
                    <div onClick={() => {navigator.clipboard.writeText(this.state.textToCopy)}}><a className='at investor' href={`mailto:${'losdiablosnft@gmail.com'}?subject=${"Investor"}&body=${""}`}>losdiablosnft@gmail.com</a></div>  
                </Typography>

                <Typography
                    className="contactTitle hide-desktop"
                    sx={{
                      fontFamily: "Rum Raisin",
                      color: "#FFCE00",
                      position: "",
                      lineHeight: "1.5",
                      width: "auto",
                      marginLeft: "10%",
                      marginTop: "15%",
                      textAlign: "center",
                    }}
                    fontSize={{
                        xs: "24px;",
                        sm: "34px",
                        lg: "40px;",
                      }} 
                                     
                >
                    <div onClick={() => {navigator.clipboard.writeText(this.state.textToCopy)}}><a className='at investor' href={`mailto:${'losdiablosnft@gmail.com'}?subject=${"Los Diablos"}&body=${""}`}>losdiablosnft@gmail.com</a></div>  
                </Typography>
                
                
                    <img
                        ref={parallax.ref}
                        src={diabla}
                        className="hide-mobile diabla"
                        alt=""
                    />
                
                <img
                    ref={parallax.ref}
                    src={floor}
                    className="parallaxElements hide-mobile floor"
                    alt=""
                />
                
                <img
                    ref={parallax.ref}
                    src={floorMobile}
                    className="parallaxElements hide-desktop floor"
                    alt=""
                />
                <img
                    ref={parallax.ref}
                    src={diablaMobile}
                    className="hide-desktop diabla-mobile"
                    alt=""
                />
                <img
                    ref={parallax.ref}
                    src={llamas}
                    className="parallaxElements hide-mobile llamas"
                    alt=""
                />
                <img
                    ref={parallax.ref}
                    src={llamasMobile}
                    className="parallaxElements hide-desktop llamas"
                    alt=""
                />
                <MainNavComp/>
            </section>
        </div>
        
    </React.Fragment>
  )
}

export default HeroContact