import { Grid, Typography, Modal } from "@mui/material";
import { Fade} from "react-reveal";
import Shake from 'react-reveal/Shake';
import "./Fotp.modules.css";
import fotpG from "../../../assets/Fotp/fotp.gif";
import bts from "../../../assets/Fotp/bts.gif";
import lyric from "../../../assets/Fotp/lyric.gif";
import { useState,useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Box from "@mui/material/Box";
import CancelIcon from "../../../assets/Team/Cancel.png";
import Pulse from "react-reveal/Pulse";
import CardContent from "@mui/material/CardContent";
import more from "../../../assets/Fotp/more.png";
import cloud from "../../../assets/Fotp/clouds2.png";


const FotpComp = () => {

    const [open, isModalOpen] = useState(false);
    const handleClose = () => isModalOpen(false);
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "#E8154E",
      borderRadius: "3%",
      border: "2px solid #FFFFFF",
      boxShadow: 24,
      p: 4,
      "&:focus": {
        outline: "none",
      },
    };

return(
    <div className="fotpContainer">

        <Fade left>
            <img src={cloud} className="cloudsF hide-mobile" alt=""/>
        </Fade>
        <div className="fotpCopy2">
            <Typography
                className=""
                sx={{
                fontFamily: "Rum Raisin",
                margin: "0%",
                color: "#FFF",
                position: "",
                lineHeight: "1",
                width: "100%",
                padding: "0% 0%",
                textAlign: "center",
                }}
                fontSize={{
                xs: "40px;",
                sm: "calc(50px + 30 *((100vw - 768px)/672));",
                lg: "80px;",
                }}
            >
                <Fade >“FREAK OUT THE PEOPLE”</Fade>
            </Typography>
            <Typography
                className=""
                sx={{
                fontFamily: "Rum Raisin",
                margin: "0%",
                color: "#FFF",
                position: "",
                lineHeight: "1",
                width: "100%",
                padding: "0% 0%",
                textAlign: "center",
                }}
                fontSize={{
                xs: "25px;",
                sm: "calc(25px + 15 *((100vw - 768px)/672));",
                lg: "40px;",
                }}
            >
                <Fade >A multimedia project by Baby Yors</Fade>
            </Typography>
            <div className="moreFotpContainerD">
                <Fade right>
                <Shake>
                    <img src={more} className="moreFotp" alt="" onClick={()=> isModalOpen(true)}/>
                </Shake>     
                </Fade>
            </div>
        </div>
        <div className="fotpWrapper" >
            <div className="gifContainerF">
                <a href="https://youtu.be/W67BJFjg7X8" target="_blank"><img src={fotpG} className="fotpGifR" alt=""/></a>
            </div>
            <div className="gifContainerF">
                <a href="https://youtu.be/oCQ7y93ZrKM" target="_blank"><img src={lyric} className="fotpGif" alt=""/></a>
            </div>
            <div className="gifContainerF gifContainerF3">
                <a href="https://youtu.be/VFgiy6n_QKU" target="_blank"><img src={bts} className="fotpGifL" alt=""/></a>      
            </div>
        </div>
        <div className="carouselContainer">
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                <SwiperSlide >
                     <div className="carouselFoptItem">
                        <a href="https://youtu.be/oCQ7y93ZrKM" target="_blank"><img src={lyric} className="fotpGif" alt=""/></a>
                        <Typography
                            sx={{
                                fontFamily: "Rum Raisin",
                                margin: "0%",
                                color: "#FFF",
                                position: "",
                                lineHeight: "1",
                                width: "100%",
                                padding: "0% 0%",
                                textAlign: "center",
                            }}
                            fontSize={{
                                xs: "25px;",
                                sm: "calc(25px + 50 *((100vw - 768px)/672));",
                                lg: "25px;",
                            }}
                        >
                            Lyric Video
                        </Typography>
                    </div>   
                </SwiperSlide>
                <SwiperSlide>
                    <div className="carouselFoptItem">
                        <a href="https://youtu.be/W67BJFjg7X8" target="_blank"><img src={fotpG} className="fotpGif" alt=""/></a>
                        <Typography
                            sx={{
                                fontFamily: "Rum Raisin",
                                margin: "0%",
                                color: "#FFF",
                                position: "",
                                lineHeight: "1",
                                width: "100%",
                                padding: "0% 0%",
                                textAlign: "center",
                            }}
                            fontSize={{
                                xs: "15px;",
                                sm: "calc(25px + 50 *((100vw - 768px)/672));",
                                lg: "25px;",
                            }}
                        >
                            Music Video
                        </Typography>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="carouselFoptItem">
                        <a href="https://youtu.be/VFgiy6n_QKU" target="_blank"><img src={bts} className="fotpGif" alt=""/></a>
                        <Typography
                            sx={{
                                fontFamily: "Rum Raisin",
                                margin: "0%",
                                color: "#FFF",
                                position: "",
                                lineHeight: "1",
                                width: "100%",
                                padding: "0% 0%",
                                textAlign: "center",
                            }}
                            fontSize={{
                                xs: "15px;",
                                sm: "calc(25px + 50 *((100vw - 768px)/672));",
                                lg: "25px;",
                            }}
                        >
                            Behind The Scenes                        
                        </Typography>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className="moreFotpContainerM">
                <Fade >
                <Shake>
                    <img src={more} className="moreFotp" alt="" onClick={()=> isModalOpen(true)}/>
                </Shake>     
                </Fade>
            </div>
        </div>
        <Modal open={open} className="teamModal " sx={{}}>
            <Box sx={style} className="modalSeed">
            <Pulse delay={500}>
                <img
                alt=""
                src={CancelIcon}
                className="cancelIcon"
                onClick={handleClose}
                />
            </Pulse>
            
            <CardContent>
                <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                marginTop={0}
                >
                <Typography
                    id="modal-modal-description"
                    color="white"
                    sx={{ mt: 0 }}
                    fontSize={{
                    xs: "14px;",
                    sm: "16px;",
                    lg: "18px;",
                    }}
                >
                    An infectious dance track with catchy choruses and a heavy Andean influence in the beat, “Freak Out The People” features lyrics like,“El diablo me ama ¡oh, Mama! (Hey, Mom! The Devil loves me) / Freak out the people, ¡oh, Papa! (Dad).” It is as much a celebration as it is a call to arms. This is reflected in the video for the song, which was directed, choreographed, edited, styled, and produced by Baby Yors. 
                    <br/>
                    <br/>
                    Filmed in various locations including Utah, Arizona, California, New York, Nevada, and Argentina, it’s set to evoke Baby Yors’s native roots in Jujuy, Argentina, and takes visual cues from “Bajada de Los Diablos,” a carnival tradition that Baby grew up witnessing. 
                    <br/>
                    <br/>
                    The costumes were carefully designed by Baby himself, and handcrafted in Argentina during the pandemic with the help of several artists in Jujuy, Cordoba, Corrientes, and Buenos Aires. 
                    <br/>
                    <br/>
                    According to Baby, “Something alternately new and traditional took shape — a perfect combination for the international introduction of Andean culture to new generations.”    
                    </Typography>
                </Grid>
            </CardContent>
            </Box>        
        </Modal>
    </div>
    )

};

export default FotpComp;

/*
<Grid container justifyContent="center"  spacing={12}>
            <Grid item xs={3} className="gri">
                <img src={fotpG} className="fotpGifR" alt=""/>
            </Grid>
            <Grid item xs={3} className="gri">
                <img src={lyric} className="fotpGif" alt=""/>
            </Grid>
            <Grid item xs={3} className="gri">
                <img src={bts} className="fotpGifL" alt=""/>
            </Grid>
        </Grid>

   <Carousel>
            <img src={fotpG} className="fotpGif" alt=""/>
            <img src={lyric} className="fotpGif" alt=""/>
            <img src={bts} className="fotpGif" alt=""/>
        </Carousel>     
*/