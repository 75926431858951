import React from 'react'
import Footer from '../../components/english/Footer/Footer';
import Carnaval from '../../components/origins/Carnaval/Carnaval';
import FotpComp from '../../components/origins/FotpComp/Fotp'
import Hero from '../../components/origins/HeroSection/Hero'
import Seed from '../../components/origins/Seed/Seed';
import Section from '../../components/origins/Section';
import Press from '../../components/origins/Press/Press';
import LinksPress from '../../components/origins/Press/LinksPress';
import Slider from '../../components/origins/Slider/Slider';
import "../../components/english/HP/Homepage.modules.css"
import SliderJujuy from '../../components/origins/SliderJujuy/SliderJujuy';

function Origins() {
  return (
    <div className='originsContainer'>
        <Hero/>
        <FotpComp/>
        <Seed/>
        <Section/>
        <Carnaval/>
        <Press/>
        
        <Footer/>
    </div>
  )
}

export default Origins