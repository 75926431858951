import React from "react";
import "./Hero.modules.css";
import background from "../../../assets/Origins/back.png";
import backgroundMobile from "../../../assets/OriginsMobile/background-mobile.png";
import houses from "../../../assets/Origins/houses.png";
import housesMobile from "../../../assets/OriginsMobile/houses.png";
import firstMountain from "../../../assets/Origins/mountain-3.png";
import firstMountainMobile from "../../../assets/OriginsMobile/mountain1.png";
import secondMountain from "../../../assets/Origins/mountain-origins.png";
import secondMountainMobile from "../../../assets/OriginsMobile/mountain2.png";
import thirdMountainMobile from "../../../assets/OriginsMobile/mountain3.png";
import thirdMountain from "../../../assets/Origins/second-mountain.png";
import sun from "../../../assets/Origins/sun-origins.png";
import sunMobile from "../../../assets/OriginsMobile/sun-mobile.png";
import cloudsMobile from "../../../assets/OriginsMobile/clouds-mobile.png";
import clouds from "../../../assets/Origins/clouds.svg";
import { Parallax, useParallax } from "react-scroll-parallax";
import Fade from 'react-reveal/Fade';
import Shake from 'react-reveal/Shake';
import MainNavComp from "../../english/MainNav/MainNav";
import { Typography } from "@mui/material";

const parallax =
  useParallax <
  HTMLDivElement >
  {
    easing: "easeOutQuad",
    translateY: [0, 100],
  };

function Hero () {
  return (
    <React.Fragment>
      <div className="mainCont" id="Home">
        <section className="pngSection">
          
          <img
            ref={parallax.ref}
            src={backgroundMobile}
            className="parallaxElements hide-desktop background"
            alt=""
          />
          <img
            ref={parallax.ref}
            src={background}
            className="parallaxElements hide-mobile background"
            alt=""
          />
          <Typography
            className="originsTitle"
            sx={{
              fontFamily: "Rum Raisin",
              margin: "0%",
              color: "#FFCE00",
              position: "",
              lineHeight: "1.5",
              width: "100%",
              padding: "0% 0%",
              textAlign: "center",
            }}
            fontSize={{
              xs: "50px;",
              sm: "50px;",
              lg: "100px;",
            }}
          >
            
            
            <div class="waviy">
              <span>O</span>
              <span>R</span>
              <span>I</span>
              <span>G</span>
              <span>I</span>
              <span>N</span>
              <span>S</span>
              </div>
            
          </Typography>

          <img
            ref={parallax.ref}
            src={secondMountainMobile}
            className="parallaxElements hide-desktop secondMountain"
            alt=""
          />
          <img
            ref={parallax.ref}
            src={secondMountain}
            className="parallaxElements hide-mobile secondMountain"
            alt=""
          />
          
          
          
            <img
              src={firstMountain}
              className="parallaxElements hide-mobile firstMountain"
              alt=""
            />
          
          <Fade bottom>
            <img
              ref={parallax.ref}
              src={sun}
              className="parallaxElements hide-mobile suno"
              alt=""
            />
          </Fade>
          
          <img
            src={sunMobile}
            className="parallaxElements hide-desktop sun"
            alt=""
          />
          
          <img
            src={thirdMountainMobile}
            className="parallaxElements hide-desktop thirdMountain"
            alt=""
          />
          <Fade bottom>
          <img
            src={thirdMountain}
            className="parallaxElements hide-mobile thirdMountain"
            alt=""
          />
          </Fade>
         
          <img
            src={housesMobile}
            className="parallaxElements hide-desktop houses"
            alt=""
          />
          <Fade bottom>
          <img
            src={houses}
            className="parallaxElements hide-mobile houses"
            alt=""
          />
          </Fade>
          <MainNavComp/>
        </section>
      </div>
    </React.Fragment>
  )
}

export default Hero