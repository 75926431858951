import { autocompleteClasses, Grid, Modal, Typography } from '@mui/material';
import React, { useState } from 'react'
import jujuy from "../../../assets/Jujuy/jujuy.png";
import "./Section.modules.css";
import Box from "@mui/material/Box";
import CancelIcon from "../../../assets/Team/Cancel.png";
import Pulse from "react-reveal/Pulse";
import CardContent from "@mui/material/CardContent";
import more from "../../../assets/Jujuy/more.png";
import { Fade } from 'react-reveal';
import Shake from 'react-reveal/Shake';
import Juju1 from "../../../assets/Jujuy/j1.png";
import Juju2 from "../../../assets/Jujuy/j2.png";
import Juju3 from "../../../assets/Jujuy/j3.png";
import Juju4 from "../../../assets/Jujuy/j4.png";
import Juju5 from "../../../assets/Jujuy/j5.png";
import Juju6 from "../../../assets/Jujuy/j6.png";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Opacity } from '@mui/icons-material';

const itemData = [{img: Juju1, title: 'Jujuy image 1', rows: 2, cols: 2}, {img: Juju2, title: 'Jujuy image 2'}, {img: Juju3, title: 'Jujuy image 3'}, {img: Juju4, title: 'Jujuy image 4', cols: 2}, {img: Juju5, title: 'Jujuy image 5', rows: 2, cols: 2}, {img: Juju6, title: 'Jujuy image 6', rows: 2, cols: 2}]


function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

const Section = () => {
    const [open, isModalOpen] = useState(false);
    const handleClose = () => isModalOpen(false);
    const [openJ, isModalOpenJ] = useState(false);
    const handleCloseJ = () => isModalOpenJ(false);
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "#E8154E",
      borderRadius: "3%",
      border: "2px solid #FFFFFF",
      width: 500,
      height: 'auto',
      p: 4,
      "&:focus": {
        outline: "none !important",
      }
    };

  return (
    <div className='sectionContainer'>
        
            <div className='textWrapper'>
            <Typography
            className=''
                    sx={{
                        fontFamily: "Rum Raisin",
                        margin: "0%",
                        color: "#FFF",
                        position: "",
                        lineHeight: "1,5",
                        padding: "2% 0%",
                        textAlign: "center",
                    }}
                    fontSize={{
                        xs: "40px;",
                        sm: "calc(50px + 30 *((100vw - 768px)/672));",
                        lg: "80px;",
                      }}
              >
            <Fade left>Jujuy</Fade> 
            </Typography>
            
                <Typography
                    className=''
                    sx={{
                        fontFamily: "Rum Raisin",
                        margin: "0%",
                        color: "#FFF",
                        position: "",
                        lineHeight: ".75",
                        padding: "0% 0%",
                        textAlign: "center",
                    }}
                    fontSize={{
                        xs: "16px;",
                        sm: "calc(20px + 20 *((100vw - 768px)/672));",
                        lg: "40px;",
                      }}
                >
                <Fade left>
                    Baby Yors takes his inspiration for “Freak Out the People” <br class='hide-mobile'/> and Los Diablos from the unexplored lands in the north of <br class='hide-mobile'/>his native country, Argentina.
                </Fade> 
                </Typography>
                
                <div className='moreCont'>
                    <Shake>
                        <img src={more} className="moreJujuyD" alt='' onClick={()=> isModalOpen(true)}/>
                    </Shake>
                </div>
            </div>
            <div className='imageSectionWrapper hide-mobile' >
                <Fade right>

                    <img
                        src={jujuy}
                        className="imageSection"
                        alt=""
                        onClick={()=> isModalOpenJ(true)}
                    />
                </Fade>
                <Shake >
                    <img src={more} className="moreJujuyM" alt='' onClick={()=> isModalOpen(true)}/>
                </Shake>
            </div>
            <div className='imageSectionWrapper hide-desktop' >
                    <img
                        src={jujuy}
                        className="imageSection"
                        alt=""
                        
                    />
                <Shake>
                    <img src={more} className="moreJujuyM" alt='' onClick={()=> isModalOpen(true)}/>
                </Shake>
            </div>
            <Modal open={open} className="teamModal " maxWidth={{sm: '100%'}}sx={{ }}>
            <Box sx={style}>
            <Pulse delay={500}>
                <img
                alt=""
                src={CancelIcon}
                className="cancelIcon"
                onClick={handleClose}
                />
            </Pulse>
            
            <CardContent>
                <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                marginTop={0}
                >
                <Typography
                    id="modal-modal-description"
                    color="white"
                    sx={{ mt: 0 }}
                    fontSize={{
                    xs: "14px;",
                    sm: "18px;",
                    lg: "16px;",
                    }}
                >
                    Jujuy is a provincia (or province) is in the far northwest of Argentina, bordering Chile (west) and Bolivia (north). San Salvador de Jujuy, in the far southeast, is the provincial capital.
                    <br/>
                    <br/>
                    Jujuy encompasses several cordilleras of the Andes Mountains that descend on the east to form sub-Andean ranges and valleys. The cordilleras reach elevations of 16,500 feet (or 5,000 meters) and are separated by the eastern portion of a bleak tableland, the Atacama Plateau, that’s 11,500 feet (or 3,500 meters) high.                    <br/>
                    <br/>
                    </Typography>
                </Grid>
            </CardContent>
            </Box>        
        </Modal>
        <Modal open={openJ} className="sectionContainer" sx={{}}>
            <Box>
              <Pulse delay={500}>
                  <img
                  alt=""
                  src={CancelIcon}
                  className="cancelIcon"
                  onClick={handleCloseJ}
                  />
              </Pulse>
              <div className='jujuyContainerModal'>
                <ImageList
                    sx={{ width: '90%', height: 'auto', border: 'none ' }}
                    variant="quilted"
                    cols={4}
                    rowHeight={121}
                    >
                    {itemData.map((item) => (
                        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                        <img
                            {...srcset(item.img, 121, item.rows, item.cols)}
                            alt={item.title}
                            loading="lazy"
                        />
                        </ImageListItem>
                    ))}
                </ImageList>
                
              </div>
            </Box>       
        </Modal>
    </div>
  )
}

export default Section