import "./About.modules.css";
import aboutLogo from "../../../assets/About/about-logo.svg";
import freakOutTape from "../../../assets/About/freakoutfinal.gif";
import { Parallax } from "react-scroll-parallax";
import Typography from "@mui/material/Typography";
import { fontFamily } from "@mui/system";
import Fade from "react-reveal/Fade";

const AboutComp = () => (
  <div className="aboutContainer" id="About">
    <Fade left delay={1100}>
      <img
        sx={{}}
        src={aboutLogo}
        className="textPng hide-mobile shadowfilter"
        alt=""
      />
    </Fade>
    <div className="aboutCopyCont">
      <Typography
        className="aboutCopyTitle"
        sx={{
          fontFamily: "Rum Raisin",
          margin: "0%",
          color: "#07FB04",
          position: "",
          lineHeight: "1.5",
          width: "100%",
          padding: "0% 0%",
          textAlign: "center",
        }}
        fontSize={{
          xs: "50px;",
          sm: "calc(50px + 50 *((100vw - 768px)/672));",
          lg: "100px;",
        }}
      >
        <Fade right>About</Fade>
      </Typography>
      <div className="about-logo-m">
        <Fade left>
          <img
            sx={{}}
            src={aboutLogo}
            className="textPng shadowfilter hide-desktop"
            alt=""
          />
        </Fade>
      </div>
      <Fade left>
        <Typography
          className="aboutCopy"
          sx={{
            fontFamily: "Mukta",
            margin: "1rem 0",
            color: "white",
            position: "",
            lineHeight: "1.5",
            width: "100%",
            marginLeft: "0%",
            padding: "2% 0%",
            textAlign: "center",
          }}
          fontSize={{
            xs: "25px;",
            sm: "calc(25px + 25 *((100vw - 768px)/672));",
            xlg: "50px;",
          }}
        >
          Los Diablos is an NFT collection of 6,666
          <br className="hide-mobile" /> randomly generated PFPs on the Ethereum
          <br className="hide-mobile" /> blockchain with all layers created by
          artist and musician <nobr>Baby Yors.</nobr>
        </Typography>
      </Fade>
    </div>

    <div className="freakoutCont hide-mobile">
      <img src={freakOutTape} className="freakOut hide-mobile" alt="" />
    </div>
  </div>
);

export default AboutComp;
