import { Typography } from "@mui/material";
import React from "react";
import merchadise from "../../../assets/Merch/merchadise.png";
import "./MerchTwo.modules.css";
import { Fade } from "react-reveal";
import diablo from "../../../assets/Merch/gif2.gif";
import funnyGirl from "../../../assets/Merch/funnyGirl.gif";
import backgroundPattern from "../../../assets/Merch/backgroundPatternMerch.png";
import more from "../../../assets/Fotp/more.png";
import Clouds2 from "../../../assets/clouds2.png";

const MerchTwo = () => {
  return (
    <div className="merchContainerTwo">
      <div class="leftContainer hide-mobile">
        <div class="devilWrapperTwo">
          <img src={diablo} className="merchDiablosImg" alt="" />
        </div>
        <img src={backgroundPattern} className="merchImg" alt="" />
        <div calss="backgroundWrapperTwo"></div>
      </div>
      <div className="merchTitleWrapperTwo">
	  <div class='merchContents'>
        <div class="merchMobileItems">
        <Typography
          sx={{
            fontFamily: "Rum Raisin",
            margin: "0%",
            color: "#FFFFFF",
            position: "",
            lineHeight: "1,5",
            padding: "40% 0% 0%",
            textAlign: "center",
          }}
          fontSize={{
            xs: "30px;",
            sm: "calc(75px + 75 *((100vw - 768px)/672));",
            lg: "150px;",
          }}
        >
          <Fade top>Merch</Fade>
        </Typography>
        <div class="devilWrapperTwo">
          <img src={more} className="moreMerchTwo" alt="" />
        </div>
        <div class="cloudWrapperTwo hide-desktop">
          <img src={Clouds2} className="moreMerchTwo" alt="" />
        </div>
		</div>
		
        <div class="funnyGirlMobileWrapper hide-desktop">
          <img src={funnyGirl} className="funnyGirlMobile" alt="" />
        </div>
		</div>
      </div>
      <div className="patternWrapper hide-mobile">
        <img src={backgroundPattern} className="merchImg" alt="" />
        <img src={backgroundPattern} className="merchImg" alt="" />
        <img src={backgroundPattern} className="merchImg" alt="" />
        <img src={backgroundPattern} className="merchImg" alt="" />
        <img src={backgroundPattern} className="merchImg" alt="" />
        <img src={backgroundPattern} className="merchImg" alt="" />
        <img src={backgroundPattern} className="merchImg" alt="" />
      </div>
    </div>
  );
};

export default MerchTwo;
