import React from 'react'
import "./Slider.modules.css"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
import Carnaval1 from "../../../assets/Carnaval/c1.png";
import Carnaval2 from "../../../assets/Carnaval/c2.png";
import Carnaval3 from "../../../assets/Carnaval/c3.png";
import Carnaval4 from "../../../assets/Carnaval/c4.png";
import Carnaval5 from "../../../assets/Carnaval/c5.png";
import Carnaval6 from "../../../assets/Carnaval/c6.png";

gsap.registerPlugin(ScrollTrigger);

const Slider = () => {

  const panels = useRef([]);
  const panelsContainer = useRef(null);
  const tl = gsap.timeline();

  const createPanelsRefs = (panel, index) => {
    panels.current[index] = panel;
  };

  useEffect(() => {
    const totalPanels = panels.current.length;
    console.log(panels);

    const totalScroll = panelsContainer.scrollWidth - panelsContainer.offsetWidth;

    tl.add(gsap.to(panels.current, {
      xPercent: -100 * (totalPanels - 1),
      ease: "none",
      scrollTrigger: {
        trigger: panelsContainer.current,
        pin: true,
        scrub: 1,
        snap: 1 / (totalPanels - 1),
        start: "top top",
        // base vertical scrolling on how wide the container is so it feels more natural.
        end: () => "+=" + panelsContainer.current.offsetWidth
      }
    }));
  }, []);

  return (
    <>
      <div className="slidercontainer" ref={panelsContainer}>
        <div
          className="description panel"
          ref={(e) => createPanelsRefs(e, 0)}
        >
            <img src={Carnaval1} className="imageSl" alt=''/>
        </div>
        <section className="panel " ref={(e) => createPanelsRefs(e, 1)}>
            <img src={Carnaval2} className="imageSl" alt=''/>
        </section>
        <section className="panel " ref={(e) => createPanelsRefs(e, 2)}>
            <img src={Carnaval3} className="imageSl" alt=''/>
        </section>
        <section className="panel " ref={(e) => createPanelsRefs(e, 3)}>
            <img src={Carnaval4} className="imageSl" alt=''/>
        </section>
        <section className="panel " ref={(e) => createPanelsRefs(e, 4)}>
            <img src={Carnaval5} className="imageSl" alt=''/>
        </section>
        <section className="panel " ref={(e) => createPanelsRefs(e, 5)}>
            <img src={Carnaval6} className="imageSl" alt=''/>
        </section>
      </div>
    </>
  )
}

export default Slider