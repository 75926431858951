import { Grid, Modal, Typography } from '@mui/material';
import React, { useState } from 'react'
import carnaval from "../../../assets/Carnaval/carnaval.gif";
import "./Carnaval.modules.css";
import Box from "@mui/material/Box";
import CancelIcon from "../../../assets/Team/Cancel.png";
import Pulse from "react-reveal/Pulse";
import CardContent from "@mui/material/CardContent";
import more from "../../../assets/Carnaval/more.png";
import { Fade } from 'react-reveal';
import Shake from 'react-reveal/Shake';
import Carnaval1 from "../../../assets/Carnaval/c1.png";
import Carnaval2 from "../../../assets/Carnaval/c2.png";
import Carnaval3 from "../../../assets/Carnaval/c3.png";
import Carnaval4 from "../../../assets/Carnaval/c4.png";
import Carnaval5 from "../../../assets/Carnaval/c5.png";
import Carnaval6 from "../../../assets/Carnaval/c6.png";
import Slider from '../Slider/Slider';

const Carnaval = () => {
  const [open, isModalOpen] = useState(false);
  const [openC, isModalOpenC] = useState(false);
  const handleClose = () => isModalOpen(false);
  const handleCloseC = () => isModalOpenC(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#E8154E",
    borderRadius: "3%",
    border: "2px solid #FFFFFF",
    boxShadow: 24,
    p: 4,
    "&:focus": {
      outline: "none",
    },
    "MuiBackdrop-root": {
      bgcolor: 'rgba(0, 0, 0, .80) !important'
    }
  };
  return (
    <div className='carnavalContainer'>
    
        <div className='imageCarnavalWrapper hide-m'>
          <Fade left>
            <img
              
              src={carnaval}
              className="imageCarnaval"
              alt=""
              onClick={()=> isModalOpenC(true)} 
              
            />
          </Fade>
          
        </div>
        <div className='textWrapper'>
            <Typography
            className='hide-mobile'
                    sx={{
                        fontFamily: "Rum Raisin",
                        margin: "0%",
                        color: "#FFF",
                        position: "",
                        lineHeight: "1,5",
                        padding: "2% 0%",
                        textAlign: "center",
                    }}
                    fontSize={{
                      xs: "40px;",
                      sm: "calc(50px + 30 *((100vw - 768px)/672));",
                      lg: "80px;",
                      }}
              >
            <Fade right>
              Carnaval
            </Fade>
            </Typography>
            <Typography
                className='hide-mobile'
                sx={{
                    fontFamily: "Rum Raisin",
                    margin: "0%",
                    color: "#FFF",
                    position: "",
                    lineHeight: ".75",
                    padding: "0% 0%",
                    textAlign: "center",
                }}
                fontSize={{
                  xs: "25px;",
                  sm: "calc(20px + 20 *((100vw - 768px)/672));",
                  lg: "40px;",
                  }}
            >
              <Fade right>
                El Carnaval de Humahuaca is the colorful celebration in <br/>Jujuy that highlights desire and freedom, and directly <br/>inspired the Los Diablos collection.
              </Fade>
            </Typography>
            <div className='moreC hide-m'>
              <Fade right>
                <Shake>
                  <img src={more} className="moreCarnaval" alt='' onClick={()=> isModalOpen(true)}/>
                </Shake>
              </Fade>
            </div>
        </div>
        <div className='imageCarnavalWrapper hide-d'>
        <img
              
              src={carnaval}
              className="imageCarnaval"
              alt=""
              
              
            />
          <img src={more} className="moreCarnaval" alt='' onClick={()=> isModalOpen(true)}/>
        </div>
        <Modal open={open} className="teamModal " sx={{}}>
            <Box sx={style} className="modalCarnaval">
            <Pulse delay={500}>
                <img
                alt=""
                src={CancelIcon}
                className="cancelIcon"
                onClick={handleClose}
                />
            </Pulse>
            
            <CardContent>
                <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                marginTop={0}
                >
                <Typography
                    id="modal-modal-description"
                    color="white"
                    sx={{ mt: 0 }}
                    fontSize={{
                    xs: "9px;",
                    sm: "14px;",
                    lg: "16px;",
                    }}
                >
                    El Carnaval de Humahuaca is a popular celebration held at the beginning of Lent, throughout the Quebrada de Humahuaca, in the Northwestern Province of Jujuy, Argentina. Introduced by the Spaniards in South America, the Carnival gradually merged with native rituals and beliefs and it’s a major opportunity to celebrate the fertility of the land while honoring the Pachamama (Mother Earth), the Andean major deity. This tradition has indigenous, Spanish and Creole reminiscences.
                    <br/>
                    <br/>
                    The celebration begins in each community with the Unearthing of El Diablo, also called Coludo, Pujllay or Supay, in Quechua, represented by a rag doll that was buried at the end of the previous Carnival. Through this cathartic festival, that typically last three weeks, every repressed desire is released, hard drinking is allowed and moral precepts are shelved. El Diablo is among the most vibrant and colorful characters of the Andean popular culture and became a significant ambassador of the Quebrada of Humahuaca, a valley that was enlisted by UNESCO as a World Heritage Site (2003) and that belongs to El Camino Inca — a major cultural route that extends from Quito, Ecuador, to Mendoza, Argentina, through Perú and Bolivia.
                    <br/>
                    <br/>
                    Los Diablos, which are only visible during the Carnival weeks, perform traditional dances and songs descending from Tilcara’s mountains in a spectacular parade and roaming in the streets while playing traditional instruments, such as the erkenchos, annates and bombos. Their colorful costumes and masks are renewed every year, using bells and mirrors, and are either burnt or buried as soon as the Carnival is over. Those who have the honor to represent El Diablo also have the privilege to open these celebrations throwing talc and confetti while sharing alcohol, basil and coca leaves with the people who gather around them to celebrate. Los Diablos are organized into a series of Comparsas, locally formed groups, each of which represents a different neighborhood or a small village. Every Comparsa has a specific name, its own portfolio of songs and dances, and compete with the others to achieve the most powerful and original representation of El Diablo, seeking protection and abundance through the year. Despite being Carnival, being a Diablo it’s a serious matter.
                    <br/>
                    <br/>
                    With the goal to enhance the cultural heritage of the Northwestern Region and, in particular, of the province of Jujuy, BIOPHILIA shaped this project to promote the traditions of a selected group of Comparsas based in the Quebrada of Humahuaca, mainly in the towns of Tilcara and Humahuaca, creating synergies with the rural farmers and turning traditional culture into an important added value for the whole region.                    
                    </Typography>
                </Grid>
            </CardContent>
            </Box>        
        </Modal>
        <Modal open={openC} className="carnavalContainer" sx={{}}>
            <Box>
              <Pulse delay={500}>
                  <img
                  alt=""
                  src={CancelIcon}
                  className="cancelIcon"
                  onClick={handleCloseC}
                  />
              </Pulse>
              <div className='carnavalContainerModal'>
                <Fade delay={450}>
                    <img src={Carnaval1} className="imageCarnavalM img-1"/>
                </Fade>
                <Fade delay={350}>
                    <img src={Carnaval2} className="imageCarnavalM img-2"/>
                </Fade>
                <Fade delay={600}>
                    <img src={Carnaval3} className="imageCarnavalM img-3"/>
                </Fade>
                <Fade delay={720}>
                    <img src={Carnaval4} className="imageCarnavalM img-4"/>
                </Fade>
                <Fade delay={540}>
                    <img src={Carnaval5} className="imageCarnavalM img-5"/>
                </Fade>
                <Fade delay={660}>
                    <img src={Carnaval6} className="imageCarnavalM img-6"/>
                </Fade>
              </div>
            </Box>       
        </Modal>
</div>
  )
}

export default Carnaval