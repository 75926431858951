import "./DiablosGrid.modules.css";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import diablo0 from "../../../assets/DiabloGrid/diablo0.gif";
import diablo1 from "../../../assets/DiabloGrid/diablo1.gif";
import diablo2 from "../../../assets/DiabloGrid/diablo2.gif";
import diablo3 from "../../../assets/DiabloGrid/diablo3.gif";
import diablo4 from "../../../assets/DiabloGrid/diablo4.gif";
import diablo5 from "../../../assets/DiabloGrid/diablo5.gif";
import diablo6 from "../../../assets/DiabloGrid/diablo6.gif";
import diablo7 from "../../../assets/DiabloGrid/diablo7.gif";
import diablo8 from "../../../assets/DiabloGrid/diablo8.gif";
import diablo9 from "../../../assets/DiabloGrid/diablo9.gif";
import diablo10 from "../../../assets/DiabloGrid/diablo10.gif";
import diablo11 from "../../../assets/DiabloGrid/diablo11.gif";
import diablo12 from "../../../assets/DiabloGrid/diablo12.gif";
import diablo13 from "../../../assets/DiabloGrid/diablo13.gif";
import diablo14 from "../../../assets/DiabloGrid/diablo14.gif";

import diabloM3 from "../../../assets/DiablosGridMobile/4.gif";
import diabloM5 from "../../../assets/DiablosGridMobile/5.gif";
import diabloM1 from "../../../assets/DiablosGridMobile/6.gif";
import diabloM4 from "../../../assets/DiablosGridMobile/8.gif";
import diabloM2 from "../../../assets/DiablosGridMobile/9.gif";
import diabloM6 from "../../../assets/DiablosGridMobile/10.gif";
import diabloM9 from "../../../assets/DiablosGridMobile/11.gif";
import diabloM8 from "../../../assets/DiablosGridMobile/12.gif";
import diabloM7 from "../../../assets/DiablosGridMobile/15.gif";
import Fade from "react-reveal/Fade";


function DiablosGrid() {
  return (
    <div className="gifContainer">
      <Fade bottom>
        <Grid container className="hide-mobile">
        <Grid item xs={2.4}><img src={diablo0} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo1} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo2} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo3} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo4} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo5} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo6} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo7} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo8} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo9} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo10} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo11} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo12} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo13} className="diabloGif" alt="" /></Grid>
        <Grid item xs={2.4}><img src={diablo14} className="diabloGif" alt="" /></Grid>
        </Grid>
        </Fade>
      <Fade bottom>
        <Grid container className="hide-desktop">
          <Grid item xs={4}><img src={diabloM1} className="diabloGif" alt="" /></Grid>
          <Grid item xs={4}><img src={diabloM2} className="diabloGif" alt="" /></Grid>
          <Grid item xs={4}><img src={diabloM3} className="diabloGif" alt="" /></Grid>
          <Grid item xs={4}><img src={diabloM4} className="diabloGif" alt="" /></Grid>
          <Grid item xs={4}><img src={diabloM5} className="diabloGif" alt="" /></Grid>
          <Grid item xs={4}><img src={diabloM6} className="diabloGif" alt="" /></Grid>
          <Grid item xs={4}><img src={diabloM7} className="diabloGif" alt="" /></Grid>
          <Grid item xs={4}><img src={diabloM8} className="diabloGif" alt="" /></Grid>
          <Grid item xs={4}><img src={diabloM9} className="diabloGif" alt="" /></Grid>
        </Grid>
      </Fade>  
      </div>
  );
}

export default DiablosGrid;