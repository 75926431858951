import { Typography } from '@mui/material';
import React from 'react';
import { Fade } from 'react-reveal';
import diablaH from '../../../assets/Press/diablaPress.png';
import diabla from "../../../assets/Press/pj2.gif";
import logo from "../../../assets/Press/logoBY2.png";
import pressL from "../../../assets/Press/pressLogos.png";
import billboard from "../../../assets/Press/billboard.png";
import ladygunn from "../../../assets/Press/ladygunn.png";
import people from "../../../assets/Press/people.png";
import wonderland from "../../../assets/Press/wonderland.png";
import cloud from "../../../assets/clouds2.png";
import "./Press.modules.css";

const Press = () => {
  return (
    <div className='pressContainer'>
          
                
                <Typography
                    className='roadmap-title'
                    sx={{
                        fontFamily: "Rum Raisin",
                        margin: "0%",
                        color: "#FFF",
                        position: "",
                        lineHeight: "1,5",
                        padding: "0% 0%",
                        textAlign: "center",
                    }}
                    fontSize={{
                        xs: "40px;",
                        sm: "calc(50px + 30 *((100vw - 768px)/672));",
                        lg: "80px;",
                    }}
                >
                    <Fade left>
                        Press
                    </Fade>
                </Typography>

                <img src={diabla} className="pressImg"/>
                
                <div className='pressCardCL pressCard-5'>
                    <a href='https://www.instagram.com/babyyors/' target="_blank"><img className='mediaLogo' src={logo} alt=""/></a>
                </div>
                <div className='pressCardC pressCard-1'>
                    <a href='https://www.wonderlandmagazine.com/2022/03/11/premiere-baby-yors-freak-out-the-people/baby-yors/' target="_blank"><img className='mediaLogo' src={wonderland} alt=""/></a>
                </div>

                <div className='pressCardC pressCard-2'>
                    <a href='https://billboard.com.ar/baby-yors-llevo-colores-al-desierto-en-su-nuevo-videoclip-freak-out-the-people/' target="_blank"><img className='mediaLogo' src={billboard} alt=""/></a>
                </div>

                <div className='pressCardC pressCard-3'>
                    <a href='https://www.ladygunn.com/people/interviews-people/freak-out-the-people/amp/' target="_blank"><img className='mediaLogo' src={ladygunn} alt=""/></a>
                </div>

                <div className='pressCardC pressCard-4'>
                    <a href='https://peopleenespanol.com/chica/rosalia-natti-natasha-el-alfa-new-music/' target="_blank"><img className='mediaLogo' src={people} alt=""/></a>
                </div>

                <div className='imgCardC pressCloud-1'>
                    <img className='mediaLogo' src={cloud} alt=""/>
                </div>
                <div className='imgCardC pressCloud-2'>
                    <img className='mediaLogo' src={cloud} alt=""/>
                </div>
                    
    </div>
  )
}

export default Press