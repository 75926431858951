import React, { useState, useEffect } from "react";
import "./Jujey.modules.css";
import Typography from "@mui/material/Typography";
import jujeyRoad from "../../../assets/Roadmap/jujeyroadfinal.gif";
import RoadMapAngel1 from "../../../assets/Roadmap/roadmap_angel1.png";
import RoadMapAngel2 from "../../../assets/Roadmap/roadmap_angel2.png";
import RoadMapAngel3 from "../../../assets/Roadmap/roadmap_angel3.png";
import RoadMapAngel4 from "../../../assets/Roadmap/roadmap_angel4.png";
import Rotate from "react-reveal/Rotate";
import Roll from "react-reveal/Roll";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "../../../assets/Team/Cancel.png";
import Pulse from "react-reveal/Pulse";
import CardContent from "@mui/material/CardContent";
import Shake from "react-reveal/Shake";

const roadMapAngel1 = RoadMapAngel1;
const roadMapAngel2 = RoadMapAngel2;
const roadMapAngel3 = RoadMapAngel3;
const roadMapAngel4 = RoadMapAngel4;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "#F14902",
  borderRadius: "3%",
  border: "2px solid #FFFFFF",
  boxShadow: 24,
  p: 4,
  "&:focus": {
    outline: "none",
  },
};

const roadmapItems = [
	{
	  id: 0,
	  mainDivClasses: "jujeyCard jujeyCard-1",
	  descriptionPart1:
		"IRL marketing impact program: targeted billboards, wheatpasting, and guerilla marketing.",
	  descriptionPart2:
		"Merchandise partnership with Merchadise.com: Through this partnership, every NFT holder is able to purchase unique, traceable, and authenticatable merch items that will feature the NFT(s) they buy.",
	  sold: "25% NFTs Sold",
	  angelImg: roadMapAngel1,
	},
	{
	  id: 1,
	  mainDivClasses: "jujeyCard jujeyCard-2",
	  descriptionPart1:
		'??????',
	  sold: "50% NFTs Sold",  
	  angelImg: roadMapAngel2,
	},
	{
	  id: 2,
	  mainDivClasses: "jujeyCard jujeyCard-3",
	  descriptionPart1:
		"?????",
	  sold: "75% NFTs Sold",
	  angelImg: roadMapAngel3,
	},
	{
	  id: 3,
	  mainDivClasses: "jujeyCard jujeyCard-4",
	  descriptionPart1:
		"?????",
	  descriptionPart2:
		"And lots of exciting partnerships, exclusive items, IRL and metaverse gatherings… things that we don’t want to share yet.",
	  sold: "100% NFTs Sold",
	  angelImg: roadMapAngel4,
	},
  ];

function Jujey() {
  const [open, isModalOpen] = useState(false);
  const [roadmapItem, setRoadmapItem] = useState(0);
  const handleClose = () => isModalOpen(false);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 768;

  const onClickRoadmapModal = (id) => {
    console.log("isMobile: ", isMobile);
    if (isMobile) {
      console.log("id: ", id);
      isModalOpen(true);
      setRoadmapItem(id);
    } else {
      isModalOpen(false);
    }
  };

  return (
    <div className="jujeyCont">
      {roadmapItems.map((roadmapItem, i) => (
        <div className={roadmapItem.mainDivClasses}>
          <div className="soldWrapper hide-mobile">
            <img
              src={roadmapItem.angelImg}
              className="jujeyAngel hide-mobile"
              alt=""
            />
              
          </div>
          <Rotate>
            <Shake delay={1800}>
              <img
                src={roadmapItem.angelImg}
                className="jujeyAngel hide-desktop"
                alt=""
                onClick={() => onClickRoadmapModal(i)}
              />
            </Shake>
          </Rotate>
          <div className="jujuyText">
            <Typography
              className="jujuyCopy"
              sx={{
                fontFamily: "Lato",
                margin: "0%",
                color: "#fff",
                position: "",
                lineHeight: "1.5",
                width: "100%",
                padding: "10%",
                textAlign: "center",
              }}
              fontSize={{
                xs: "10px;",
                sm: "calc(10px + 10 *((100vw - 768px)/672));",
                md: "16px;",
              }}
            >
              <b class='sold'>{roadmapItem.sold}</b>
              <br /> <br />
              {roadmapItem.descriptionPart1}
              <br /> <br />
              {roadmapItem.descriptionPart2}
              {roadmapItem.descriptionPart3 ? (
                <React.Fragment>
                  <br /> <br />
                </React.Fragment>
              ) : null}
              {roadmapItem.descriptionPart3}
              {roadmapItem.descriptionPart4 ? (
                <React.Fragment>
                  <br /> <br />
                </React.Fragment>
              ) : null}
              {roadmapItem.descriptionPart4}
            </Typography>
          </div>
        </div>
      ))}
      <div className="jujeyImg">
        <img src={jujeyRoad} className="jujey" alt="" />
      </div>
      <Modal open={open} className="teamModal " sx={{}}>
        <Box sx={style} >
          <div className="soldWrapper">
          <Pulse delay={500}>
            <img
              alt=""
              src={CancelIcon}
              className="cancelIcon"
              onClick={handleClose}
            />
          </Pulse>
          </div>
          <CardContent>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              marginTop={0}
            >
              
              <Typography
                id="modal-modal-description"
                color="white"
                sx={{ mt: 0 }}
                fontSize={{
                  xs: "16px;",
                  sm: "18px;",
                  lg: "16px;",
                }}
              >
                <b class='sold'>{roadmapItems[roadmapItem].sold}</b>
                <br /> <br />
                {roadmapItems[roadmapItem].descriptionPart1}
                <br /> <br />
                {roadmapItems[roadmapItem].descriptionPart2}
                {roadmapItems[roadmapItem].descriptionPart3 ? (
                  <React.Fragment>
                    <br /> <br />
                  </React.Fragment>
                ) : null}
                {roadmapItems[roadmapItem].descriptionPart3}
                {roadmapItems[roadmapItem].descriptionPart4 ? (
                  <React.Fragment>
                    <br /> <br />
                  </React.Fragment>
                ) : null}
                {roadmapItems[roadmapItem].descriptionPart4}
              </Typography>
            </Grid>
          </CardContent>
        </Box>
      </Modal>
    </div>
  );
}

export default Jujey;
