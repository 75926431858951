import LosDiablosEng from "./containers/LosDiablosEng/LosDiablosEng";
import { ParallaxProvider } from "react-scroll-parallax";
import MainNavComp from "./components/english/MainNav/MainNav";
import Origins from "./containers/OriginsPage/Origins";



function App() {
  return (
    <div className="App" style={{background: 'transparent linear-gradient(184deg, #212765 0%, #25276E 31%, #1C427E 64%, #21699B 94%, #2271A1 100%) 0% 0% no-repeat padding-box'}}>
      <ParallaxProvider>
        
        <LosDiablosEng />
      </ParallaxProvider>
    </div>
  );
}

export default App;
