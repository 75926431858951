import React from "react";
import "./CloudParallax.modules.css";
import Clouds from "../../../assets/Homepage/clouds.png";
import Clouds2 from "../../../assets/clouds2.png";
import Clouds3 from "../../../assets/clouds3.png";
import Parallax, { Layer, AppearLayer } from "react-parallax-scroll";


function CloudComp() {
    return (
      <div className="parallaxCloudCont"><Parallax>
      <Layer
        className="clouds2 clouds"
        settings={{ speed: .1, type: ["translateX"] }}
      >
        <img src={Clouds2} className="clouds2" alt=""></img>
      </Layer>
      <Layer
        className="clouds3 clouds"
        settings={{ speed: .5, type: ["translateX"] }}
      >
        <img src={Clouds3} className="clouds3" alt=""></img>
      </Layer>
      <Layer
        className="clouds4 clouds"
        settings={{ speed: .2, type: ["translateX"] }}
      >
      <img src={Clouds2} className="clouds4" alt=""></img></Layer>
    </Parallax></div>
    );
  }

export default CloudComp;