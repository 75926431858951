import "./FooterTape.modules.css";
import footerTape from "../../../assets/Footer/footer-tape.png";

function FooterTape() {
    return (
      <div className="footer-tape-cont">
        
      </div>
    );
  }
  
  export default FooterTape;