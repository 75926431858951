import React from 'react'
import Footer from '../../components/english/Footer/Footer';
import MainNavComp from '../../components/english/MainNav/MainNav';
import Events from '../../components/Irl/Events/Events';
import Hoodie from '../../components/Irl/Hoodie/Hoodie';
import Llama from '../../components/Irl/Llama/Llama';
import VideoIrl from '../../components/Irl/VideoIrl/VideoIrl';
import "./Irl.modules.css";

const Irl = () => {
  return (
    <div className='irlContainer'>
      <MainNavComp/>
      <VideoIrl />
      <Footer />
      
    </div>
  )
}

export default Irl