import React, { useEffect, useState } from "react";
import UseIsMobile from "../UseIsMobile/UseIsMobile";
import isMobile from "../UseIsMobile/UseIsMobile";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import sandboxImage from "../../../assets/SandboxModal/sandboxpromo1.gif";
import sandboxImageMobile from "../../../assets/SandboxModal/sandboxpromo1m.gif";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 420,
  bgcolor: "#fed500",
  borderRadius: "3%",
  backgroundImage: `url(${sandboxImage})`,
  backgroundPosition: "center",
  backgroundRepeat: "repeat",
  backgroundSize: "95%",
  border: "2px solid #FFFFFF",
  boxShadow: 24,
  p: 4,
  "&:focus": {
    outline: "none",
  },
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: 450,
  bgcolor: "#F14902",
  borderRadius: "3%",
  backgroundImage: `url(${sandboxImageMobile})`,
  backgroundPosition: "center",
  backgroundRepeat: "repeat",
  backgroundSize: "95%",
  border: "2px solid #FFFFFF",
  boxShadow: 24,
  p: 4,
  "&:focus": {
    outline: "none",
  },
};

export default function SandboxModal() {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      
        <Modal
          open={open}
          onClose={handleClose}
          className="sandboxModal"
          sx={{}}
        >
          <Box sx={style2}></Box>
        </Modal>
      
    </div>
  );
}
