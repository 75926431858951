import React from "react";
import "./Homepage.modules.css";
import background from "../../../assets/Homepage/background.png";
import backgroundMobile from "../../../assets/HomepageMobile/background-mobile.png";
import buildings from "../../../assets/Homepage/buildings.png";
import buildingsMobile from "../../../assets/HomepageMobile/buildings-mobile.png";
import rightMountain from "../../../assets/Homepage/Right-Mountain.png";
import rightMountainMobile from "../../../assets/HomepageMobile/right-mountain-mobile.png";
import leftMountain from "../../../assets/Homepage/left-mountain.png";
import leftMountainMobile from "../../../assets/HomepageMobile/left-mountain-mobile.png";
import sun from "../../../assets/Homepage/sun.png";
import sunMobile from "../../../assets/HomepageMobile/sun-mobile.png";
import tree from "../../../assets/Homepage/tree.png";
import clouds from "../../../assets/Homepage/clouds.png";
import treeMobile from "../../../assets/HomepageMobile/tree-mobile.png";
import diablo from "../../../assets/Homepage/diablo_hp_small.svg";
import diabloMobile from "../../../assets/HomepageMobile/diablo-mobile.png";
import logoMobile from "../../../assets/HomepageMobile/logo-mobile.png";
import logoMain from "../../../assets/Homepage/logoMain.svg";
import cloudsMobile from "../../../assets/HomepageMobile/clouds-mobile.png";
import Parallax, { Layer, AppearLayer } from "react-parallax-scroll";
import MainNav from "../MainNav/MainNav";
import Fade from 'react-reveal/Fade';
import SandboxModal from "../SandboxModal/SandboxModal";



function HomepageComp() {
  return (
    <React.Fragment>
	
      <div className="hpMainCont" id="Home">
	  
      <MainNav/>
	  
        <section className="pngSection">
		
          <Parallax>
		  
          <img
            src={background}
            className="parallaxElements hide-mobile background"
            alt=""
          />
          <img
            src={backgroundMobile}
            className="parallaxElements hide-desktop rightMountain"
            alt=""
          />
          <Layer
            className="rightMountain parallaxElements hide-mobile cloudsParallax"
            settings={{ speed: -1, type: ["translateX"] }}>
          <img
            src={clouds}
            className="parallaxElements hide-mobile rightMountain"
            alt=""
          />
          </Layer>
          <Layer
            className="leftMountain parallaxElements hide-mobile"
            settings={{ speed: -.5, type: ["translateX"] }}>
          <img
            src={leftMountain}
            className="parallaxElements hide-mobile leftMountain"
            alt=""
          />
          </Layer>

          <img
            src={leftMountainMobile}
            className="parallaxElements hide-desktop leftMountain"
            alt=""
          />
          <Layer
            className="rightMountain parallaxElements hide-mobile"
            settings={{ speed: .7, type: ["translateX"] }}>
          <img
            src={rightMountain}
            className="parallaxElements hide-mobile rightMountain"
            alt=""
          />
          </Layer>
          <img
            src={rightMountainMobile}
            className="parallaxElements hide-desktop rightMountain"
            alt=""
          />
          <Layer
            className="rightMountain parallaxElements hide-mobile"
            settings={{ speed: .9, type: ["translateX"] }}>
          <img src={diablo} className=" diabloHP hide-mobile" alt="" /></Layer>

          <img src={sun} className="parallaxElements hide-mobile sun" alt="" />
          <img
            src={sunMobile}
            className="parallaxElements hide-desktop sun"
            alt=""
          />
          <Fade bottom>
          <img
            src={diabloMobile}
            className="parallaxElements diablo diabloMobile hide-desktop"
            alt=""
          /></Fade>
          <img
            src={tree}
            className="parallaxElements hide-mobile trees"
            alt=""
          />
          <img
            src={treeMobile}
            className="parallaxElements hide-desktop trees"
            alt=""
          />
          <img
            src={cloudsMobile}
            className="parallaxElements hide-desktop trees"
            alt=""
          />

          <img
            src={buildings}
            className="parallaxElements hide-mobile buildings"
            alt=""
          />
          <img
            src={buildingsMobile}
            className="parallaxElements hide-desktop buildings"
            alt=""
          />
          <div className="logo-container-start">
            <div className="logo-cont">
            <Layer
            className="parallaxElements buildings hide-mobile"
            settings={{ speed: .9, type: ["translateX"] }}>
            <Fade left>
            
              <img
                src={logoMain}
                className="parallaxElements logoMain hide-mobile buildings"
                alt=""
              /></Fade>
              </Layer>
              <Fade left>
              <img
                src={logoMobile}
                className="parallaxElements logo-mobile hide-desktop buildings"
                alt=""
              /></Fade>
            </div>
          </div>
          </Parallax> 
        </section>
      </div>
    </React.Fragment>
  );
}

export default HomepageComp;