import "./AboutMore.modules.css";
import Typography from "@mui/material/Typography";
import Fade from "react-reveal/Fade";

function AboutMore() {
  return (
    <div className="aboutMoreContainer">
      <Fade left>
        <Typography
          className="aboutMoreCopy"
          sx={{
            fontFamily: "Mukta",
            margin: "3rem 0",
            color: "white",
            position: "",
            lineHeight: "1.5",
            width: "100%",
            padding: "2% 0%",
            textAlign: "center",
          }}
          fontSize={{xs: '25px;', sm: 'calc(25px + 25 *((100vw - 768px)/672));', xlg:'50px;'}}
        >
        The artwork is inspired by the Carnival rituals of<br class="hide-mobile"/> Jujuy, Baby’s hometown in the north mountains of<br class="hide-mobile"/> Argentina. During the festive rituals, citizens let the <br class="hide-mobile"/> devil “be among them,” releasing their inhibitions<br class="hide-mobile"/> through wild joy and dance.<br/><br/> Los Diablos brings this tradition to Web3 for<br class="hide-mobile"/> everyone to experience.
        </Typography>
        </Fade>
    </div>
  );
}

export default AboutMore;
