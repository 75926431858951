import "./Roadmap.modules.css";
import Typography from "@mui/material/Typography";
import roadmapImg from "../../../assets/Roadmap/roadmapfinal.gif";
import Fade from "react-reveal/Fade";

function Roadmap() {
  return (
    <div className="roadmapContainer" id="Roadmap">
      <Typography
        className="roadmap-title"
        sx={{
          fontFamily: "Rum Raisin",
          margin: "0%",
          color: "#07FB04",
          position: "",
          lineHeight: "1.5",
          width: "100%",
          padding: "3% 0%",
          textAlign: "center",
        }}
        fontSize={{
          xs: "40px;",
          sm: "calc(40px + 40 *((100vw - 768px)/672));",
          lg: "80px;",
        }}
      >
        <Fade delay={300} bottom>Roadmap</Fade>
      </Typography>
      <div className="roadmapImg">
        <img src={roadmapImg} className="roadmap" alt="" />
      </div>
    </div>
  );
}

export default Roadmap;
