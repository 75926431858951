import { Typography } from '@mui/material';
import React from 'react'
import vid from "../../../assets/Video/VideoDiablo.mp4";
import MainNavComp from '../../english/MainNav/MainNav';
import "./VideoIrl.modules.css"

const VideoIrl = () => {
  return (
    <div className='videoContainer'>
      <video className='videoPlayer' src={vid}  autoPlay loop muted contrls={false}>
        
      </video>
      <Typography
            className="titleIrl hide-desktop"
            sx={{
              fontFamily: "Rum Raisin",
              margin: "0%",
              color: "#F8008B",
              position: "",
              lineHeight: "1.5",
              width: "100%",
              padding: "0% 0%",
              textAlign: "center",
            }}
            fontSize={{
              xs: "50px;",
              sm: "calc(50px + 50 *((100vw - 768px)/672));",
              lg: "100px;",
            }}
      >
          (?) 
      </Typography>
      
    </div>
  )
}

export default VideoIrl