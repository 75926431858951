import { Routes, Route  } from 'react-router-dom';
import HomepageComp from '../../components/english/HP/Homepage';
import AboutComp from '../../components/english/About/About';
import DiablosGrid from '../../components/english/DiablosGrid/DiablosGrid';
import AboutMore from '../../components/english/AboutMore/AboutMore';
import TeamComp from '../../components/english/Team/Team';
import Roadmap from '../../components/english/Roadmap/Roadmap';
import Jujey from '../../components/english/Roadmap/Jujey';
import Clouds from '../../components/english/CloudParallax/CloudParallax';
import FooterTape from '../../components/english/FooterTape/FooterTape';
import { Parallax } from 'react-scroll-parallax';
import './LosDiablosEng.modules.css';
import Footer from '../../components/english/Footer/Footer';
import Llama from '../../components/Irl/Llama/Llama';

function LosDiablosEng() {
    return (
        <div className='englishContainer'>

            <HomepageComp />
            <AboutComp />
            <DiablosGrid />
            <AboutMore />
            <Clouds />
            <TeamComp />
            <Roadmap />
            <Jujey />
            <Llama/>
            <Footer />
        </div>
    );
}

export default LosDiablosEng;