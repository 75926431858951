import React from 'react';
import "./Llama.modules.css";
import llama from "../../../assets/Llama/Escena.png";

const Llama = () => {
  return (
    <div className='llamaContainer'>
        <div className='llamaImgCont'>
            <img className='llamaImg' src={llama} alt=''/>
        </div>
    </div>
  )
}

export default Llama