import React from 'react'
import "./SliderJuju.modules.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
import Juju1 from "../../../assets/Jujuy/j1.png";
import Juju2 from "../../../assets/Jujuy/j2.png";
import Juju3 from "../../../assets/Jujuy/j3.png";
import Juju4 from "../../../assets/Jujuy/j4.png";
import Juju5 from "../../../assets/Jujuy/j5.png";
import Juju6 from "../../../assets/Jujuy/j6.png";

gsap.registerPlugin(ScrollTrigger);

const SliderJujuy = () => {
    const panels = useRef([]);
    const panelsContainer = useRef(null);
    const tl = gsap.timeline();
  
    const createPanelsRefs = (panel, index) => {
      panels.current[index] = panel;
    };
  
    useEffect(() => {
      const totalPanels = panels.current.length;
      console.log(panels);
  
      const totalScroll = panelsContainer.scrollWidth - panelsContainer.offsetWidth;
  
      tl.add(gsap.to(panels.current, {
        xPercent: -100 * (totalPanels - 1),
        ease: "none",
        scrollTrigger: {
          trigger: panelsContainer.current,
          pin: true,
          scrub: 1,
          snap: 1 / (totalPanels - 1),
          start: "top top",
          // base vertical scrolling on how wide the container is so it feels more natural.
          end: () => "+=" + panelsContainer.current.offsetWidth
        }
      }));
    }, []);
  
    return (
      <>
        <div className="slidercontainer" ref={panelsContainer}>
          <div
            className="description panel"
            ref={(e) => createPanelsRefs(e, 0)}
          >
              <img src={Juju1} className="imageSl" alt=''/>
          </div>
          <section className="panel " ref={(e) => createPanelsRefs(e, 1)}>
              <img src={Juju2} className="imageSl" alt=''/>
          </section>
          <section className="panel " ref={(e) => createPanelsRefs(e, 2)}>
              <img src={Juju3} className="imageSl" alt=''/>
          </section>
          <section className="panel " ref={(e) => createPanelsRefs(e, 3)}>
              <img src={Juju4} className="imageSl" alt=''/>
          </section>
          <section className="panel " ref={(e) => createPanelsRefs(e, 4)}>
              <img src={Juju5} className="imageSl" alt=''/>
          </section>
          <section className="panel " ref={(e) => createPanelsRefs(e, 5)}>
              <img src={Juju6} className="imageSl" alt=''/>
          </section>
        </div>
      </>
    )
}

export default SliderJujuy