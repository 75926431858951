import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TwitterIcon from "../../../assets/Socials/Twitter.png";
import InstagramIcon from "../../../assets/Socials/IG.png";
import OpenseaIcon from "../../../assets/Socials/open sea.png";
import DiscordIcon from "../../../assets/Socials/Discord.png";
import FooterTape from "../FooterTape/FooterTape";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import logoMobile from "../../../assets/logoFooter.png";
import "./Footer.modules.css";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import React from "react";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focusColor",
})((p) => ({
  "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: p.focusColor,
  },
  "& .MuiOutlinedInput-root": {
    borderColor: p.focusColor,
  },
  "& .MuiTextField-root": {
    borderColor: p.focusColor,
    color: p.focusColor,
  },
  "& .MuiOutlinedInput-root": {
    borderColor: p.focusColor,
  },
  // input label when focused
  "& label.Mui-focused": {
    color: p.focusColor,
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focusColor,
    },
  },
}));

export default function Footer() {
  return (
    <React.Fragment>
      <FooterTape />
      <footer>
        <Box>
          <Container maxWidth="xlg" className="footer-box">
            <Grid
              container
              spacing={5}
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                xs={12}
                sm={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
              <Zoom>
                <div className="svg-logo-box">
                  <img src={logoMobile} class="logoFooter" alt="" />
                </div>
                </Zoom>  
              </Grid>
              
              <Grid item xs={12} sm={6}>
              <Fade bottom>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Typography
                      className="joinCopy"
                      sx={{
                        fontFamily: "Rum Raisin",
                        margin: "0%",
                        color: "#fff",
                        position: "",
                        lineHeight: "1.5",
                        width: "100%",
                        padding: "0% 0%",
                        textAlign: "center",
                      }}
                      fontSize={{
                        xs: "20px;",
                        sm: "calc(20px + 20 *((100vw - 768px)/672));",
                        lg: "40px;",
                      }}
                    >
                      Join Our Community
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={3}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <IconButton
                        href="https://discord.gg/GhEn9RSs"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={DiscordIcon}
                          className="social-pngs footerIcons"
                          alt=""
                        />
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <IconButton
                        href="https://twitter.com/losdiablosnft"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={TwitterIcon}
                          className="social-pngs footerIcons"
                          alt=""
                        />
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <IconButton
                        href="https://instagram.com/losdiablosnft"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={InstagramIcon}
                          className="social-pngs footerIcons"
                          alt=""
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                </Fade>
              </Grid>
              
            </Grid>
          </Container>
        </Box>
      </footer>
    </React.Fragment>
  );
}
