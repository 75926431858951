import React from 'react'
import Footer from '../../components/english/Footer/Footer';
import MainNavComp from '../../components/english/MainNav/MainNav';
import Hoodie from '../../components/Irl/Hoodie/Hoodie';
import Llama from '../../components/Irl/Llama/Llama';
import MerchTwo from '../../components/Irl/MerchTwo/MerchTwo';
import "./Merch.modules.css";

const Irl = () => {
  return (
	<div class="merchBackground">
    <div className='irlContainer' id="Merch">
      <MainNavComp/>
      <MerchTwo />
      <Footer />
      
    </div>
	</div>
  )
}

export default Irl