import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TwitterIcon from "../../../assets/Socials/Twitter.png";
import InstagramIcon from "../../../assets/Socials/IG.png";
import OpenseaIcon from "../../../assets/Socials/open sea.png";
import DiscordIcon from "../../../assets/Socials/Discord.png";
//import { Link, animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
import "./MainNav.modules.css";
import { ThemeProvider } from "@mui/material/styles";

const pages = ["home", "origins", "merch", "contact"];
const links = [""];

const MainNavComp = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ bgcolor: "transparent" }}
      className="appBar"
      style={{ boxShadow: "none", background: "transparent" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "#FECE1B" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem key="home" onClick={handleCloseNavMenu}>
                <Typography
                  textAlign="center"
                  sx={{ fontFamily: "Rum Raisin" }}
                >
                  <Link
                    className="link"
                    activeClass="active"
                    to={"/"}
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    Home
                  </Link>
                </Typography>
              </MenuItem>
			  <MenuItem key="mint" onClick={handleCloseNavMenu}>
                <Typography
                  textAlign="center"
                  sx={{ fontFamily: "Rum Raisin" }}
                >
                  <a
                    href="https://mintingdapp-38t.pages.dev/"
                    target="_blank"
					rel="noopener"
                    class="external-nav-link"
                  >
                    Mint!
                  </a>
                </Typography>
              </MenuItem>
              <MenuItem key="origins" onClick={handleCloseNavMenu}>
                <Typography
                  textAlign="center"
                  sx={{ fontFamily: "Rum Raisin" }}
                >
                  <Link
                    className="link"
                    activeClass="active"
                    to={"/origins"}
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    Origins
                  </Link>
                </Typography>
              </MenuItem>
              <MenuItem key="merch" onClick={handleCloseNavMenu}>
                <Typography
                  textAlign="center"
                  sx={{ fontFamily: "Rum Raisin" }}
                >
                  <a
                    href="https://partners.merchadise.com/collections/los-diablos-nft"
                    target="_blank"
					rel="noopener"
                    class="external-nav-link"
                  >
                    Merch
                  </a>
                </Typography>
              </MenuItem>
			  <MenuItem key="irl" onClick={handleCloseNavMenu}>
                <Typography
                  textAlign="center"
                  sx={{ fontFamily: "Rum Raisin" }}
                >
                  <Link
                    className="link"
                    activeClass="active"
                    to={"/irl"}
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    (?)
                  </Link>
                </Typography>
              </MenuItem>
			  <MenuItem key="marketplace" onClick={handleCloseNavMenu}>
                <Typography
                  textAlign="center"
                  sx={{ fontFamily: "Rum Raisin" }}
                >
                  <a
                    href="https://marketplace.losdiablos.io"
                    target="_blank"
					rel="noopener"
                    class="external-nav-link"
                  >
                    Marketplace
                  </a>
                </Typography>
              </MenuItem>
              <MenuItem key="contact" onClick={handleCloseNavMenu}>
                <Typography
                  textAlign="center"
                  sx={{ fontFamily: "Rum Raisin" }}
                >
                  <Link
                    className="link"
                    activeClass="active"
                    to={"/contact"}
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    Contact
                  </Link>
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              key="home"
              sx={{
                my: 2,
                color: "white",
                display: "block",
                fontFamily: "Rum Raisin",
                fontSize: "2.2rem",
                color: "#FECE1B",
                paddingRight: "3%",
              }}
            >
              <Link
                className="link"
                activeClass="active"
                to={`/`}
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                <div className="link">HOME</div>
              </Link>
            </Button>
			<Button
              key="mint"
              sx={{
                my: 2,
                color: "white",
                display: "block",
                fontFamily: "Rum Raisin",
                fontSize: "2.2rem",
                color: "#FECE1B",
                paddingRight: "3%",
              }}
            >
              <a
                href="https://mintingdapp-38t.pages.dev/"
                target="_blank"
                rel="noopener"
                class="external-nav-link"
              >
                Mint!
              </a>
            </Button>
            <Button
              key="origins"
              sx={{
                my: 2,
                color: "white",
                display: "block",
                fontFamily: "Rum Raisin",
                fontSize: "2.2rem",
                color: "#FECE1B",
                paddingRight: "3%",
              }}
            >
              <Link
                className="link"
                activeClass="active"
                to={`/origins`}
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                <div className="link">Origins</div>
              </Link>
            </Button>
            <Button
              key="merch"
              sx={{
                my: 2,
                color: "white",
                display: "block",
                fontFamily: "Rum Raisin",
                fontSize: "2.2rem",
                color: "#FECE1B",
                paddingRight: "3%",
              }}
            >
              <a
                href="https://partners.merchadise.com/collections/los-diablos-nft"
                target="_blank"
                rel="noopener"
                class="external-nav-link"
              >
                Merch
              </a>
            </Button>
			<Button
              key="contact"
              sx={{
                my: 2,
                color: "white",
                display: "block",
                fontFamily: "Rum Raisin",
                fontSize: "2.2rem",
                color: "#FECE1B",
                paddingRight: "3%",
              }}
            >
              <Link
                className="link"
                activeClass="active"
                to={`/irl`}
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                <div>(?)</div>
              </Link>
            </Button>
			<Button
              key="marketplace"
              sx={{
                my: 2,
                color: "white",
                display: "block",
                fontFamily: "Rum Raisin",
                fontSize: "2.2rem",
                color: "#FECE1B",
                paddingRight: "3%",
              }}
            >
              <a
                href="https://marketplace.losdiablos.io"
                target="_blank"
                rel="noopener"
                class="external-nav-link"
              >
                Marketplace
              </a>
            </Button>
            <Button
              key="contact"
              sx={{
                my: 2,
                color: "white",
                display: "block",
                fontFamily: "Rum Raisin",
                fontSize: "2.2rem",
                color: "#FECE1B",
                paddingRight: "3%",
              }}
            >
              <Link
                className="link"
                activeClass="active"
                to={`/contact`}
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                <div>Contact</div>
              </Link>
            </Button>
          </Box>
		  <IconButton
            href="https://opensea.io/collection/losdiablos"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={OpenseaIcon} className="social-pngs" alt="" />
          </IconButton>
          <IconButton
            href="https://twitter.com/losdiablosnft"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={TwitterIcon} className="social-pngs" alt="" />
          </IconButton>
          <IconButton
            href="https://instagram.com/losdiablosnft"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={InstagramIcon} className="social-pngs" alt="" />
          </IconButton>
          <IconButton
            href="https://discord.gg/SnK66KCJmX"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={DiscordIcon} className="social-pngs" alt="" />
          </IconButton>
          <IconButton
            href="https://google.com"
            target="_blank"
            rel="noopener noreferrer"
          ></IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default MainNavComp;
